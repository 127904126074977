import React, { useState, useEffect } from "react";

const Dashboard = ({ billingCoding, diagnosticCoding, isLoading, onSaveClaim }) => {
  const [billingCodes, setBillingCodes] = useState([]);
  const [diagnosticCodes, setDiagnosticCodes] = useState([]);
  const [selectedBillingCodes, setSelectedBillingCodes] = useState([]);
  const [selectedDiagnosticCodes, setSelectedDiagnosticCodes] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    setBillingCodes(extractCodes(billingCoding));
    setDiagnosticCodes(extractCodes(diagnosticCoding));
    setSelectedBillingCodes([]);
    setSelectedDiagnosticCodes([]);
    setTotalAmount(0);
  }, [billingCoding, diagnosticCoding]);

  const extractCodes = (text) => {
    if (!text || text === "No billing code received" || text === "No diagnostic code received" || text.startsWith("An error occurred")) {
      return [];
    }
    return text.split('\n').map(line => {
      try {
        const [codeAndDescription, cost] = line.split(',');
        if (!codeAndDescription) return null; // Skip this line if it doesn't contain a comma
  
        const [code, ...descriptionParts] = codeAndDescription.split(':');
        if (!code) return null; // Skip this line if it doesn't contain a colon
  
        return {
          code: code.trim(),
          description: descriptionParts.join(':').trim() || 'No description',
          cost: cost ? parseFloat(cost.trim()) : 0
        };
      } catch (error) {
        console.error("Error parsing line:", line, error);
        return null; // Return null for any line that causes an error
      }
    }).filter(item => item !== null); // Remove any null items
  };  

  const toggleCodeSelection = (code, isBilling) => {
    if (isBilling) {
      setSelectedBillingCodes(prev => {
        const newSelection = prev.includes(code)
          ? prev.filter(c => c !== code)
          : [...prev, code];
        
        // Recalculate total amount
        const newTotalAmount = newSelection.reduce((total, selectedCode) => {
          const codeObj = billingCodes.find(bc => bc.code === selectedCode);
          return total + (codeObj ? codeObj.cost : 0);
        }, 0);
        setTotalAmount(newTotalAmount);
        
        return newSelection;
      });
    } else {
      setSelectedDiagnosticCodes(prev => 
        prev.includes(code) ? prev.filter(c => c !== code) : [...prev, code]
      );
    }
  };

  const renderCodes = (codes, isBilling) => {
    if (codes.length === 0) return "No code available";
    
    return codes.map(({ code, description, cost }, index) => (
      <div 
        key={index} 
        className={`mb-2 cursor-pointer p-2 rounded ${
          (isBilling ? selectedBillingCodes : selectedDiagnosticCodes).includes(code)
            ? 'bg-blue-100'
            : 'hover:bg-gray-100'
        }`}
        onClick={() => toggleCodeSelection(code, isBilling)}
      >
        <span className="font-bold text-blue-600">{code || 'No code'}</span>: {description || 'No description'}
        {isBilling && typeof cost === 'number' && <span className="ml-2 text-green-600">${cost.toFixed(2)}</span>}
      </div>
    ));
  };  

  const handleAddButtonClick = () => {
    if (selectedBillingCodes.length === 0 && selectedDiagnosticCodes.length === 0) {
      alert("Please select at least one billing or diagnostic code.");
      return;
    }
    onSaveClaim(selectedBillingCodes, selectedDiagnosticCodes, totalAmount);
  };

  return (
    <div className="h-full flex flex-col bg-[#FAF9F6] text-black">
      {isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <span className="loading loading-spinner loading-lg"></span>
        </div>
      ) : (billingCodes.length > 0 || diagnosticCodes.length > 0) ? (
        <div className="flex-1 overflow-y-auto">
          <div className="mb-4">
            <h3 className="font-semibold">Billing Codes:</h3>
            <div className="bg-white p-4 rounded-lg border border-gray-200">
              {renderCodes(billingCodes, true)}
            </div>
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">Diagnostic Codes:</h3>
            <div className="bg-white p-4 rounded-lg border border-gray-200">
              {renderCodes(diagnosticCodes, false)}
            </div>
          </div>
          <p className="mt-2 font-semibold">Total Amount: ${totalAmount.toFixed(2)}</p>
          <button 
            className="w-full bg-white text-black border-2 border-black rounded-md px-4 py-2 hover:bg-gray-100 transition-colors duration-200 mt-4" 
            onClick={handleAddButtonClick}
          >
            Save claim
          </button>
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center">
          <p className="text-black">No medical coding information available yet.</p>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
