'use client'
import { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import { FileText } from 'lucide-react'
import ClaimModal from './ClaimModal'

export default function ClaimsList() {
  const [claims, setClaims] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedClaim, setSelectedClaim] = useState(null)

  useEffect(() => {
    fetchClaims()
  }, [])

  const fetchClaims = async () => {
    try {
      setLoading(true)
      const { data, error } = await supabase
      .from('claims')
      .select(`
        *,
        patients:patient_id (
          patient_name:patient_name
        )
      `)
      .order('created_at', { ascending: false });
      
      if (error) {
        throw error;
      }
  
      // Flatten the data structure for easier use in the component
      const flattenedData = data.map(claim => ({
        ...claim,
        patient_name: claim.patients.patient_name
      }));
  
      setClaims(flattenedData)
    } catch (error) {
      setError('Error fetching claims: ' + error.message)
    } finally {
      setLoading(false)
    }
  };

  // const getStatusColor = (status) => {
  //   switch (status) {
  //     case 'Pending': return 'text-yellow-500'
  //     case 'Approved': return 'text-green-500'
  //     case 'Denied': return 'text-red-500'
  //     default: return 'text-gray-500'
  //   }
  // }

  const openClaimModal = (claim) => {
    console.log(claim.id);
    setSelectedClaim(claim)
  }

  const closeClaimModal = () => {
    setSelectedClaim(null)
  }

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return (
    <div className="container mx-auto p-6 bg-[#FAF9F6]">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Billing Claims</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {claims.map(claim => (
          <div 
            key={claim.id} 
            className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow duration-200 cursor-pointer border border-gray-100"
            onClick={() => openClaimModal(claim)}
          >
            <div className="flex justify-between items-start mb-3">
              <p className="text-gray-600 font-medium">Claim #{claim.claim_number}</p>
              <FileText className="w-5 h-5 text-purple-400" />
            </div>
            <p className="text-2xl font-bold text-gray-800 mb-2">${claim.amount}</p>
            <p className="text-sm text-black mb-4">Submitted: {new Date(claim.created_at).toLocaleDateString()}</p>
            <p className={`text-sm text-black font-medium mb-4`}>Fee codes: {claim.fee_code}</p>
            <p className={`text-sm text-black font-medium mb-4`}>Diagnostic codes: {claim.diagnostic_code}</p>
            <p className="text-black font-medium">Patient: {claim.patient_name}</p>
            <button className="w-full py-2 bg-purple-50 text-purple-600 rounded-md hover:bg-purple-100 transition-colors duration-200">
              View Details
            </button>
          </div>
        ))}
      </div>
      {selectedClaim && (
        <ClaimModal 
          claim={{
            patientName: selectedClaim.patient_name,
            claimNumber: selectedClaim.claim_number,
            amount: selectedClaim.amount,
            feeCode: selectedClaim.fee_code,
            diagnosticCode: selectedClaim.diagnostic_code,
            submittedDate: new Date(selectedClaim.created_at).toLocaleDateString(),
            description: selectedClaim.description || 'No description available'
          }}  
          onClose={closeClaimModal}
        />
      )}
    </div>
  )
}
