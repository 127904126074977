import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import CustomCalendar from './CustomCalendar';

const CreateClaim = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [patientName, setPatientName] = useState('');
  const [patientBirthdate, setPatientBirthdate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const navigate = useNavigate();
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!session) {
      console.error('User is not authenticated');
      return;
    }
    if (!patientBirthdate) {
      console.error('Patient birthdate is required');
      return;
    }
    console.log("Form submitted");
    console.log(patientBirthdate)
    try {
      const { data, error } = await supabase
        .from('patients')
        .insert([{ patient_name: patientName, dob: patientBirthdate.toISOString() }])
        .select();

      if (error) throw error;
      
      setIsModalOpen(false);
      navigate('/textbox', { state: { patientId: data[0].patient_id, patientName } });
    } catch (error) {
      console.error('Error creating patient:', error);
    }
  };

  const handleDateSelect = (date) => {
    setPatientBirthdate(date);
    setShowCalendar(false);
  };

  if (!isModalOpen) return null;
  return (
    <div className="fixed inset-0 bg-[#FAF9F6] bg-opacity-50 overflow-y-auto h-full w-full text-black">
      <div className="relative top-20 mx-auto p-5 border-2 border-black w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-xl font-bold mb-4 text-black">Create New Claim</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={patientName}
            onChange={(e) => setPatientName(e.target.value)}
            placeholder="Enter patient name"
            className="w-full p-2 mb-4 border-2 border-black rounded bg-white focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent"
            required
          />
          <div className="mb-4">
            <button
              type="button"
              onClick={() => setShowCalendar(!showCalendar)}
              className="w-full bg-[#FAF9F6] text-black border-2 border-black rounded-md px-4 py-2 hover:bg-gray-200 transition-colors duration-200"
            >
              {patientBirthdate ? patientBirthdate.toDateString() : "Select patient birthdate"}
            </button>
            {showCalendar && (
              <div className="mt-2">
                <CustomCalendar onDateSelect={handleDateSelect} />
              </div>
            )}
          </div>
          <button 
            type="submit" 
            className="w-full bg-black text-white border-2 border-[#FAF9F6] rounded-md px-4 py-2 hover:bg-gray-800 transition-colors duration-200"
          >
            Create
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateClaim;
