import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Dashboard from "./DashBoard";
import { useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const Textbox = () => {
  const [billingProcedures, setBillingProcedures] = useState([""]);
  const [diagnosticProcedures, setDiagnosticProcedures] = useState([""]);
  const [medicalCoding, setMedicalCoding] = useState({ billing: "", diagnostic: "" });
  const [isLoading, setIsLoading] = useState(false);
  const billingInputRefs = useRef([]);
  const diagnosticInputRefs = useRef([]);
  const location = useLocation();
  const [patientInfo, setPatientInfo] = useState(null);

  useEffect(() => {
    if (location.state?.patientId && location.state?.patientName) {
      setPatientInfo({
        id: location.state.patientId,
        name: location.state.patientName
      });
    }
  }, [location]);

  const handleChange = (index, value, isProcedure) => {
    const newProcedures = isProcedure ? [...billingProcedures] : [...diagnosticProcedures];
    newProcedures[index] = value;
    isProcedure ? setBillingProcedures(newProcedures) : setDiagnosticProcedures(newProcedures);
  };

  const handleKeyDown = (event, index, isProcedure) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const procedures = isProcedure ? billingProcedures : diagnosticProcedures;
      const setProcedures = isProcedure ? setBillingProcedures : setDiagnosticProcedures;
      const inputRefs = isProcedure ? billingInputRefs : diagnosticInputRefs;

      if (index === procedures.length - 1) {
        setProcedures([...procedures, ""]);
        setTimeout(() => {
          inputRefs.current[index + 1]?.focus();
        }, 0);
      }
    }
  };
  
  const removeProcedure = (index, isProcedure) => {
    const procedures = isProcedure ? billingProcedures : diagnosticProcedures;
    const setProcedures = isProcedure ? setBillingProcedures : setDiagnosticProcedures;
    const newProcedures = procedures.filter((_, i) => i !== index);
    setProcedures(newProcedures);
  };

  const getMedicalCoding = async () => {
    setIsLoading(true);
    try { 
      const [billingResponse, diagnosticResponse] = await Promise.all([
        axios.get("https://a2y8hmu7ua.execute-api.us-east-2.amazonaws.com/prod/coding/billing", {
          params: { description: billingProcedures.join("|") },
          timeout: 50000,
        }),
        axios.get("https://a2y8hmu7ua.execute-api.us-east-2.amazonaws.com/prod/coding/diagnostic", {
          params: { description: diagnosticProcedures.join("|") },
          timeout: 50000,
        })
      ]);
  
      setMedicalCoding({
        billing: billingResponse.data.ohip_code || "No billing code received",
        diagnostic: diagnosticResponse.data.ohip_code || "No diagnostic code received"
      });
    } catch (error) {
      console.error("Error in getMedicalCoding:", error);
      setMedicalCoding({
        billing: "An error occurred while fetching billing code",
        diagnostic: "An error occurred while fetching diagnostic code"
      });
    } finally {
      setIsLoading(false);
    }
  };  

  const submitClaim = async (billingCodes, diagnosticCodes, totalAmount) => {
  if (!patientInfo || billingCodes.length === 0 || diagnosticCodes.length === 0) {
    alert("Please ensure patient information and medical coding are available before submitting.");
    return;
  }

  try {
    const { data: userData, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;

    // Get or create user mapping
    let { data: userMapping, error: mappingError } = await supabase
      .from('user_mappings')
      .select('id')
      .eq('supabase_uid', userData.user.id)
      .single();

    if (mappingError && mappingError.code === 'PGRST116') {
      // User mapping doesn't exist, create it
      const { data: newMapping, error: insertError } = await supabase
        .from('user_mappings')
        .insert({ supabase_uid: userData.user.id })
        .select('id')
        .single();

      if (insertError) throw insertError;
      userMapping = newMapping;
    } else if (mappingError) {
      throw mappingError;
    }

    // Now insert the claim using the mapped user ID
    const { error } = await supabase
      .from('claims')
      .insert({
        patient_id: patientInfo.id,
        user_id: userMapping.id,
        fee_code: billingCodes.join(','),
        diagnostic_code: diagnosticCodes.join(','),
        date: new Date().toISOString().split('T')[0],
        amount: totalAmount,
      });

    if (error) throw error;

    alert("Claim submitted successfully!");
    // Optionally, you can reset the form or navigate to another page here
  } catch (error) {
    console.error("Error submitting claim:", error);
    alert("An error occurred while submitting the claim. Please try again.");
  }
};
  

  const renderInputs = (procedures, inputRefs, isProcedure) => (
    procedures.map((procedure, index) => (
      <div key={index} className="mb-2 relative">
        <input
          ref={el => inputRefs.current[index] = el}
          type="text"
          value={procedure}
          onChange={(e) => handleChange(index, e.target.value, isProcedure)}
          onKeyDown={(e) => handleKeyDown(e, index, isProcedure)}
          placeholder={`Enter ${isProcedure ? 'billing' : 'diagnostic'} procedure`}
          className="w-full p-2 bg-[#FAF9F6] text-black rounded focus:outline-none"
        />
        {index !== procedures.length - 1 && (
          <button
            onClick={() => removeProcedure(index, isProcedure)}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-red-500"
          >
            ×
          </button>
        )}
      </div>
    ))
  );

  return (
    <div className="flex h-screen bg-[#FAF9F6]">
      <div className="flex-1 flex flex-col p-5 relative text-black">
        {patientInfo && (
          <div className="mb-4">
            <h2 className="text-xl font-bold">Patient: {patientInfo.name}</h2>
            <p>Patient ID: {patientInfo.id}</p>
          </div>
        )}
        <div className="flex-1 overflow-y-auto mb-20">
          <h3 className="font-bold mb-2">Billing Procedures</h3>
          {renderInputs(billingProcedures, billingInputRefs, true)}
          <h3 className="font-bold mt-4 mb-2">Diagnostic Procedures</h3>
          {renderInputs(diagnosticProcedures, diagnosticInputRefs, false)}
        </div>
        <div className="fixed bottom-5 left-5 w-[calc(50%-2.5rem)]">
          <button
            onClick={getMedicalCoding}
            disabled={isLoading}
            className="w-full bg-white text-black border-2 border-black rounded-md px-4 py-2 hover:bg-gray-100 transition-colors duration-200"
          >
            {isLoading ? "Loading..." : "Get Medical Coding"}
          </button>
        </div>
      </div>
      <div className="flex-1 p-5">
        <Dashboard 
          billingCoding={medicalCoding.billing} 
          diagnosticCoding={medicalCoding.diagnostic} 
          isLoading={isLoading} 
          onSaveClaim={submitClaim}
        />
      </div>
    </div>
  );
};

export default Textbox;
