import { X, FileText } from 'lucide-react'

export default function ClaimModal({ claim, onClose }) {
  if (!claim) return null

  // const getStatusColor = (status) => {
  //   switch (status) {
  //     case 'Pending': return 'bg-yellow-100 text-yellow-800'
  //     case 'Approved': return 'bg-green-100 text-green-800'
  //     case 'Denied': return 'bg-red-100 text-red-800'
  //     default: return 'bg-gray-100 text-gray-800'
  //   }
  // }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-[#FAF9F6] rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto border-2 border-purple-300">
        <div className="sticky top-0 bg-white p-4 border-b border-purple-200 flex justify-between items-center">
          <h2 className="text-2xl font-bold text-black">Claim Details</h2>
          <button onClick={onClose} className="text-black hover:text-purple-800">
            <X size={24} />
          </button>
        </div>
        <div className="p-6 space-y-6">
          <div className="flex justify-between items-start">
            <h3 className="text-3xl font-bold text-black">{claim.patientName}</h3>
            <span className={`px-3 py-1 rounded-full text-sm font-semibold`}>
              {claim.status}
            </span>
          </div>
          <div className="space-y-4">
            <div className="flex items-center space-x-2 text-black">
              <FileText size={20} />
              <span className="text-lg">Claim Number: {claim.claimNumber}</span>
            </div>
            <p className="text-xl font-semibold text-black">Amount: ${claim.amount}</p>
            <p className="text-black">Submitted: {claim.submittedDate}</p>
            <div className="bg-[#FAF9F6] p-4 rounded-lg border border-purple-200">
              <h4 className="text-lg font-semibold text-black mb-2">Fee Codes</h4>
              <p className="text-black">{claim.feeCode}</p>
              <h4 className="text-lg font-semibold text-black mb-2">Diagnostic Codes</h4>
              <p className="text-black">{claim.diagnosticCode}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
