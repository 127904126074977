import React from 'react'
import { Home, Plus, Settings } from "lucide-react"
import { Link } from 'react-router-dom'

export default function Sidebar() {
  return (
    <aside className="w-64 bg-white shadow-md h-screen border-2 border-black">
      <div className="p-4 border-b-2 border-black flex items-center">
        <img src="/odu.svg" alt="Odu logo" className="w-8 h-8 mr-2" />
        <h1 className="text-2xl font-bold text-black">Odu</h1>
      </div>
      <nav className="mt-2">
        <SidebarLink to="/" icon={<Home className="w-5 h-5 mr-3" />} label="Dashboard" />
        <SidebarLink to="/create" icon={<Plus className="w-5 h-5 mr-3" />} label="Create Claim" />
        <SidebarLink to="/settings" icon={<Settings className="w-5 h-5 mr-3" />} label="Settings (Coming soon)" />
      </nav>
    </aside>
  )
}

function SidebarLink({ to, icon, label }) {
  return (
    <Link
      to={to}
      className="flex items-center px-6 py-3 text-gray-600 hover:bg-purple-50 hover:text-purple-600 transition-colors duration-200 border-b border-black"
    >
      {icon}
      {label}
    </Link>
  )
}
